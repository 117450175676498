(function() {
    'use strict';

    angular
        .module('app')
        .config(appConfig);

    appConfig.$inject = ['$locationProvider'];

    function appConfig($locationProvider) {
        angular.element('a').each(function(){
            var a = angular.element(this);
            if (a.is('[target]') || a.is('[ng-href]')){
            } else {
                a.attr('target', '_self');
            }
        });
        $locationProvider.html5Mode(true);
    }
})();
