(function () {
    'use strict';

    angular.module('app', [
        'slick',
        'ngSanitize',
        'ngMessages',
        'szallodak-offer',
        'szallodak-home-screen',
        'szallodak-search-index',
        'ui.bootstrap',
        'daterangepicker',
        'ngCookies',
        'szallodak-filter',
        'ui.checkbox',
        'angular-cookie-law',
        'szallodak-detail',
        'szallodak-detailmodal',
        'szallodak-newsletter',
    ]).filter('money', function () {
        return function (n) {
            var num = parseInt(n, 10);
            if (isNaN(num)) {
                return n;
            }
            num = ''+num;
            num = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")+' Ft';
            return num;
        };
    }).constant('datepickerLocale', {
        format: 'YYYY.MM.DD',
        separator: ' - ',
        applyLabel: 'Rendben',
        cancelLabel: 'Mégsem',
        fromLabel: 'Tól',
        toLabel: 'Ig',
        customRangeLabel: 'Egyedi',
        weekLabel: 'hét',
        daysOfWeek: [
            'Va',
            'Hé',
            'Ke',
            'Sz',
            'Cs',
            'Pé',
            'Szo',
        ],
        monthNames: [
            'Január',
            'Február',
            'Március',
            'Április',
            'Május',
            'Június',
            'Július',
            'Augusztus',
            'Szeptember',
            'Október',
            'November',
            'December'
        ],
        firstDay: 1
    }).constant('hungary', {
        longitude: 19.0402,
        latitude: 47.4979
    }).directive('ngDatepicker', function () {
        return {
            restrict: 'A',
            replace: true,
            scope: {
                ngModel: '=',
                datepickerOptions: '=',
                availableDates: '=',
                onApply: '&onApply',
                onRemove: '&onRemove',
            },
            templateUrl: '/js/szallodak/daterangepicker.html',
            link: function (scope, element) {
                scope.inputHasFocus = false;
                var datePicker1 = element.find('.startDate');
                var datePicker2 = element.find('.endDate');

                scope.datepickerOptions.beforeShowDay = function (date) {
                    var momentDate = moment(date);
                    if (momentDate < moment()) {
                        return;
                    }
                    if (scope.ngModel.startDate && momentDate.isSame(scope.ngModel.startDate)) {
                        if (scope.availableDates && scope.availableDates[moment(date).format('YYYY-MM-DD')]) {
                            return 'available-day start-day';
                        }
                        return 'start-day';
                    }

                    if (scope.ngModel.endDate && momentDate.isSame(scope.ngModel.endDate)) {
                        if (scope.availableDates && scope.availableDates[moment(date).format('YYYY-MM-DD')]) {
                            return 'available-day end-day';
                        }
                        return 'end-day';
                    }

                    if (scope.ngModel.startDate && scope.ngModel.endDate && momentDate.isBetween(scope.ngModel.startDate, scope.ngModel.endDate)) {
                        return 'selected-day';
                    }

                    if (scope.availableDates && scope.availableDates[moment(date).format('YYYY-MM-DD')]) {
                        return 'available-day';
                    }

                    return;
                };

                scope.removeDates = function() {
                    removeStartDate();
                    removeEndDate();
                    if (scope.onRemove) {
                        scope.onRemove();
                    }
                };

                datePicker1.datepicker(scope.datepickerOptions).on('changeDate', function(e) {
                    applyDate(e, 'startDate');
                    removeEndDate();
                    datePicker2.datepicker('setStartDate', moment(scope.ngModel.startDate).add(1, 'd').format('YYYY-MM-DD'));
                    datePicker2.datepicker('show');
                });
                datePicker2.datepicker(scope.datepickerOptions).on('changeDate', function(e) {
                    applyDate(e, 'endDate');
                    if (scope.ngModel.startDate && scope.ngModel.endDate) {
                        scope.onApply();
                    }
                });

                scope.$emit('datepicker-init-done');

                element.find('input').on('click', function () {
                    scope.inputHasFocus = true;
                    datePicker1.datepicker('update');
                    datePicker2.datepicker('update');
                }).on('blur', function () {
                    scope.inputHasFocus = false;
                });

                scope.$watch('ngModel', function (newValue) {
                    if (!scope.inputHasFocus) {
                        datePicker1.datepicker('update', newValue.startDate);
                        datePicker2.datepicker('update', newValue.endDate);
                    }
                });

                function applyDate(event, dateType) {
                    scope.$apply(function () {
                        scope.ngModel[dateType] = moment(event.date).format('YYYY-MM-DD');
                    });
                }

                function removeStartDate() {
                    scope.ngModel.startDate = null;
                    datePicker1.datepicker('update', '');
                    datePicker1.val('');
                }

                function removeEndDate() {
                    scope.ngModel.endDate = null;
                    datePicker2.datepicker('update', '');
                    datePicker2.val('');
                }
            }
        }
    });
})();
