(function () {
    'use strict';

    angular
        .module('szallodak-home-screen', ['uiGmapgoogle-maps'])
        .config(function (uiGmapGoogleMapApiProvider) {
            // Retrieve the API key from the meta tag - meta is not existing on public site
            const apiKey = document.querySelector('meta[name="gmap-api-key"]')?.getAttribute('content');

            uiGmapGoogleMapApiProvider.configure({
                key: apiKey,
                v: '3.52', //defaults to latest 3.X anyhow
                libraries: 'geometry,visualization,places',
            });
        });
})();
